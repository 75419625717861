<template>
    <swiper
        class="stage-slider"
        direction="horizontal"
        :speed="800"
        :modules="modules"
        loop
        :autoplay="{
            delay: 7000,
            disableOnInteraction: false,
        }"
        @swiper="(swpr) => (swiper = swpr)"
        @afterInit="manageSlideHeight"
        parallax
        :breakpoints="{
            768: {
                direction: 'vertical',
            },
        }"
        :style="{
            '--stage-biggest-slide-height': `${biggestSlideHeight}px`,
        }"
    >
        <slot />
    </swiper>
    <slot name="navigation" v-bind="{ slidePrev, slideNext }" />
</template>

<script>
import { Autoplay, Pagination, Parallax } from "swiper";

export default {
    name: "StageSlider",

    props: {
        hasMultipleChildren: {
            type: Boolean,
            default: false,
        },
    },

    data: () => {
        return {
            modules: [Autoplay, Pagination, Parallax],
            swiper: null,
            biggestSlideHeight: 0,
        };
    },

    mounted() {
        window.addEventListener("resize", this.manageSlideHeight);
        this.manageSlideHeight();
    },

    methods: {
        slideNext() {
            this.swiper?.slideNext();
        },

        slidePrev() {
            this.swiper?.slidePrev();
        },

        manageSlideHeight() {
            if (!this.swiper) {
                return;
            }

            const { el } = this.swiper;

            this.biggestSlideHeight = 0;
            [...el.querySelectorAll(".section-stage__content .text")].forEach(
                (e) => {
                    const slideHeight = e.getBoundingClientRect().height;
                    if (slideHeight > this.biggestSlideHeight) {
                        this.biggestSlideHeight = slideHeight;
                    }
                }
            );
        },
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.manageSlideHeight);
    },
};
</script>
<style lang="scss">
@use "@bam/scss" as *;

.autoplay-progress {
    position: absolute;
    right: 0;
    bottom: 80px;
    z-index: 10;
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
    grid-column-end: grid-end;

    @include mq-from(md) {
        bottom: 100px;
        grid-column-end: content-end;
    }

    @include mq-from(xl) {
        grid-column-end: grid-end;
    }
}

.autoplay-progress circle {
    stroke: v(color-primary);
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 * (1 - var(--progress, 0)));
}
</style>
