import "./scss/global.scss";
import "swiper/css";
import { createApp } from "vue";
import { useBreakpoints } from "@vueuse/core";

import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation, Pagination, Parallax, FreeMode } from "swiper";
import { ExpandTransition, FadeTransition } from "@bam/transitions";
import * as ANIMATIONS from "./animations";

const app = createApp({
    data: () => {
        return {
            globalSwiperModules: [
                Autoplay,
                Navigation,
                Pagination,
                Parallax,
                FreeMode,
            ],
        };
    },

    mounted() {
        ANIMATIONS.initKPIValues();
        ANIMATIONS.initTiles();
    },
});

const bps = JSON.parse(
    getComputedStyle(document.body).getPropertyValue("--bam-breakpoints")
);
const breakpoints = useBreakpoints(bps);
app.config.globalProperties.$breakpoints = breakpoints;

const components = import.meta.globEager("./components/**/*.vue");
Object.entries(components).forEach(([path, definition]) => {
    const componentName = path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");
    app.component(componentName, definition.default);
});

app.component("expand-transition", ExpandTransition);
app.component("fade-transition", FadeTransition);
app.component("swiper", Swiper);
app.component("swiper-slide", SwiperSlide);

app.mount("#app");
