import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const init = () => {
    const sections = gsap.utils.toArray(".section-kpi");

    sections.forEach(function (section) {
        ScrollTrigger.batch(section.querySelectorAll(".kpis__kpi"), {
            invalidateOnRefresh: true,
            start: "top bottom",
            end: "bottom top",
            onEnter: (batch) =>
                gsap.fromTo(
                    batch,
                    { autoAlpha: 0, x: "100px" },
                    {
                        autoAlpha: 1,
                        x: 0,
                        stagger: 0.15,
                        overwrite: true,
                        ease: "expo",
                        duration: 1.25,
                    }
                ),
        });
    });
};

export default init;
