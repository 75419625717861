<script setup>
import { computed } from 'vue';

const props = defineProps({
    open: {
        type: Boolean,
        default: false
    }
});


const componentClass = computed(() => {
  return {
    'expandable': true,
    'expandable--open': props.open,
  };
});
</script>

<template>
  <div :class="componentClass">
    <div class="expandable__content-animation">
      <div class="expandable__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.expandable {
  display: grid;
  grid-template-rows: 0fr;

  overflow: hidden;
  transition: grid-template-rows v(transition-disappear, $skipExistsCheck: true);

  &__content-animation {
    min-height: 0;
  }

  &__content {
    //     padding: 0 0 30px 0;
  }

  &--open {
    grid-template-rows: 1fr;
    transition: grid-template-rows v(transition-appear, $skipExistsCheck: true);
  }
}
</style>
