<template>
    <div class="section-lion__slider">
        <swiper
            class="lion-slider-main"
            :speed="800"
            :modules="[Controller, EffectFade]"
            @swiper="setFirstSwiper"
            :controller="{ control: secondSwiper }"
        >
            <slot name="main" />
        </swiper>
    </div>

    <div class="section-lion__navigation lion-slider-navigation">
        <swiper
            :speed="800"
            :modules="[Controller]"
            @swiper="setSecondSwiper"
            :controller="{ control: firstSwiper }"
            slides-per-view="auto"
            :space-between="30"
            :centered-slides="true"
            @slideChange="onSlideChange"
            @afterInit="onSlideChange"
            :slide-to-clicked-slide="true"
        >
            <slot name="nav" />
        </swiper>
    </div>
</template>

<script>
import { ref } from "vue";
import { Controller, EffectFade } from "swiper";

export default {
    setup() {
        const firstSwiper = ref(null);
        const secondSwiper = ref(null);
        const setFirstSwiper = (swiper) => {
            firstSwiper.value = swiper;
        };
        const setSecondSwiper = (swiper) => {
            secondSwiper.value = swiper;
        };

        const onSlideChange = (swiper) => {
            const slide = swiper.slides[swiper.activeIndex];

            if (!slide) {
                setColor(null);
                return;
            }

            const color = slide.getAttribute("data-effect-color");
            if (!color) {
                setColor(null);
                return;
            }

            setColor(color);
        };

        const setColor = (color) => {
            const section = firstSwiper.value.el.closest("section");
            if (!section) {
                return;
            }
            section.style.setProperty(
                "--bam-section-slideshow-context-color",
                color
            );
        };

        return {
            Controller,
            EffectFade,
            firstSwiper,
            secondSwiper,
            setFirstSwiper,
            setSecondSwiper,
            onSlideChange,
            setColor,
        };
    },
};
</script>
