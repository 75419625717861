<template></template>

<script>
import { Autoplay, FreeMode } from "swiper";

export default {
    name: "NavigationSlider",

    data: () => {
        return {
            modules: [Autoplay, FreeMode],
            swiper: null,
        };
    },

    methods: {},
};
</script>
